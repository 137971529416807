<template>
  <div>
     <el-table :data="tableData" v-loading="loading" style="width: 100%"
    max-height="700" border >
      <el-table-column
        prop="projectTitle"
        label="项目名">
      </el-table-column>

      <el-table-column
        prop="totalPlanAmount"
        label="预估计佣金额">
      </el-table-column>

       <el-table-column
        prop="totalPlanCommission"
        label="预估计佣">
      </el-table-column>

      <el-table-column
        prop="totalRealAmount"
        label="实际计佣金额">
      </el-table-column>

      <el-table-column
        prop="totalRealCommission"
        label="实际佣金">
      </el-table-column>

      <el-table-column
        prop="msg"
        label="提示">
      </el-table-column>

    </el-table>
     <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import { getProjectOrder } from "@/api/projectorder";

  export default {
    
    data() {
      return {
      projectId: '',
      tableData: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      };
    },
    mounted(){
this.search()
    },
    methods: {
      async search(val){
           this.loading = true;
        await getProjectOrder()
          .then((res) => {
            this.loading = false;
            if (res.code == 1000) {
      this.tableData = res.data;
      this.total = res.data.total;
          }else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search(); // 重新搜索以更新分页数据
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search(); // 重新搜索以更新分页数据
    }
    }
  };
</script>

